"use client";

import { FC, MouseEvent, useState } from "react";
import { useDropzone } from "react-dropzone";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import { useStyles } from "./styles";
import { useAppDispatch } from "@/redux/store";
import { generateAlert } from "@/redux/actions";
import ImageUploadModal from "../ImageUploadModal";

const MAX_FILE_SIZE = 10; // max file size in MB

interface ImageSelectorProps {
  setImage: (image: File) => void;
  className?: string;
  heading?: string;
  uploadOnly?: boolean;
}

const ImageSelector: FC<ImageSelectorProps> = ({
  setImage,
  className,
  heading = "Select base image",
  uploadOnly = false,
}) => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [modalOpen, setModalOpen] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/webp": [".webp"],
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE * 1024 * 1024,
    onDropRejected(fileRejections, event) {
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          let errorMessage = error.message;
          if (error.code === "file-too-large") {
            errorMessage = `File too large. Max file size is ${MAX_FILE_SIZE}MB`;
          }
          dispatch(generateAlert({ text: errorMessage, type: "error" }));
        });
      });
    },
    onDrop: (acceptedPhotos) => {
      if (acceptedPhotos.length === 0) return;
      setImage(acceptedPhotos[0]);
    },
  });

  const handleCardClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (uploadOnly) {
      open();
    } else {
      setModalOpen(true);
    }
  };

  const handleOpenFileExplorer = () => {
    open();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Card
        className={cx(classes.container, className)}
        {...getRootProps()}
        onClick={handleCardClick}
      >
        <div className={classes.textContainer}>
          <input {...getInputProps()} />
          <Typography variant="h6" className={classes.heading}>
            {heading}
          </Typography>
          <Typography variant="body1" color="secondary">
            {isMobile ? (
              "Tap here to upload an image*"
            ) : (
              <>
                Click <u>here</u> or drag & drop an image file*
              </>
            )}
          </Typography>
          <Typography variant="body2">
            *Max file size {MAX_FILE_SIZE}MB
          </Typography>
        </div>
      </Card>
      <ImageUploadModal
        open={modalOpen}
        onClose={handleCloseModal}
        setImage={setImage}
        openFileExplorer={handleOpenFileExplorer}
      />
    </>
  );
};

export default ImageSelector;
