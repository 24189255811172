import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{
  disabled: boolean;
  loading: boolean;
  color?:
    | "error"
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning";
}>()((theme, { disabled, loading, color }) => ({
  button: {
    width: loading ? 150 : "fit-content",
    backgroundImage: color ? "none" : theme.palette.secondary.main,
    fontWeight: 500,
    opacity: disabled ? 0.7 : 1,
    borderRadius: 5,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    [theme.breakpoints.up("lg")]: {
      borderRadius: 10,
    },
  },
}));
