"use client";

import { FC, useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import { PiMinusCircleBold, PiPlusCircleBold } from "react-icons/pi";
import Slider from "@mui/material/Slider";

import { useStyles } from "./styles";
import Button from "@/components/common/PrimaryButton";

interface ProfilePictureUpdaterProps {
  image: File | string;
  setImage: (image: File | string) => void;
  onCancel: () => void;
}

const ProfilePictureUpdater: FC<ProfilePictureUpdaterProps> = ({
  image,
  setImage,
  onCancel,
}) => {
  const { classes } = useStyles();
  const editor = useRef<AvatarEditor | null>(null);
  const [zoom, setZoom] = useState(1);

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Limit zoom to max 3x
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Limit zoom to min 1x
  };

  const handleSliderChange = (_: Event, zoomLevel: number | number[]) => {
    if (typeof zoomLevel === "number") {
      setZoom(zoomLevel);
    }
  };

  const handleUpdateAvatar = () => {
    if (editor.current) {
      const canvas = editor.current.getImageScaledToCanvas();
      const dataUrl = canvas.toDataURL();

      setImage(dataUrl);
    }
  };

  return (
    <div>
      <AvatarEditor
        ref={editor}
        width={250}
        height={250}
        image={image}
        scale={zoom}
        borderRadius={250}
        border={0}
      />
      <div className={classes.zoomControls}>
        <PiMinusCircleBold onClick={handleZoomOut} />
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={handleSliderChange}
          aria-labelledby="zoom-slider"
          className={classes.zoomSlider}
        />
        <PiPlusCircleBold onClick={handleZoomIn} />
      </div>
      <div className={classes.modalControls}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={handleUpdateAvatar}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProfilePictureUpdater;
