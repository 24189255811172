"use client";

import { useStyles } from "./styles";
import { FC } from "react";

interface TypingSpinnerProps {
  color?: string;
}

const TypingSpinner: FC<TypingSpinnerProps> = ({
  color,
}: {
  color?: string;
}) => {
  const { classes } = useStyles({ color });

  return (
    <div className={classes.spinner}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default TypingSpinner;
