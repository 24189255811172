import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ disabled: boolean }>()(
  (theme, { disabled }) => ({
    textField: {
      "& .MuiOutlinedInput-notchedOutline": {
        boxShadow: "0px 2px 0px #DEE4EB",
      },
      "& .MuiInputBase-root": {
        padding: 0,
      },
    },
    inputAdornment: {
      paddingRight: theme.spacing(4),
      color: disabled ? "#ececec" : theme.palette.secondary.main,
      cursor: disabled ? "auto" : "pointer",
      "&:hover": {
        opacity: 0.8,
      },
    },
  })
);
