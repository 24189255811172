import { FC, useState } from "react";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import { convertImageUrlToFile } from "./helpers";
import Modal from "@/components/common/Modal";
import TextField from "@/components/common/TextField";
import Button from "@/components/common/PrimaryButton";

interface ImageUploadModalProps {
  open: boolean;
  onClose: () => void;
  setImage: (image: File) => void;
  openFileExplorer: () => void;
}

const ImageUploadModal: FC<ImageUploadModalProps> = ({
  open,
  onClose,
  setImage,
  openFileExplorer,
}) => {
  const { classes } = useStyles();
  const [imageUrl, setImageUrl] = useState("");
  const [loadingExternalImage, setLoadingExternalImage] = useState(false);
  const [imageUrlError, setImageUrlError] = useState("");

  const handleModalClose = () => {
    setImageUrl("");
    setImageUrlError("");
    onClose();
  };

  const updateImageUrl = (newUrl: string) => {
    setImageUrl(newUrl);
    setImageUrlError("");
  };

  const handleLoadImageFromUrl = async () => {
    if (!imageUrl) {
      setImageUrlError("Please enter a valid image URL");
      return;
    }

    try {
      setImageUrlError("");
      setLoadingExternalImage(true);
      const imageFile = await convertImageUrlToFile(imageUrl);
      setImage(imageFile);
      setLoadingExternalImage(false);
      onClose();
    } catch (error) {
      console.error("Error setting image from URL:", error);
      setLoadingExternalImage(false);
      setImageUrlError(
        "Failed to load image. Please select a different image or try again."
      );
    }
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <div className={classes.modalContent}>
        <div className={classes.urlControls}>
          <Typography variant="h6" className={classes.heading}>
            Enter an image URL
          </Typography>
          <TextField
            value={imageUrl}
            onUpdate={updateImageUrl}
            onSubmit={handleLoadImageFromUrl}
            placeholder="Image URL"
            loading={loadingExternalImage}
            errorMessage={imageUrlError}
            autoFocus
          />
        </div>
        <Typography variant="h6" className={classes.divider}>
          OR
        </Typography>
        <Button
          color="secondary"
          className={classes.button}
          onClick={openFileExplorer}
        >
          Upload from device
        </Button>
      </div>
    </Modal>
  );
};

export default ImageUploadModal;
