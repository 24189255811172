import axios, { AxiosRequestConfig } from "axios";

export const convertImageUrlToFile = async (
  imageUrl: string,
  imageName = "image"
) => {
  try {
    const options: AxiosRequestConfig = { responseType: "blob" };
    const response = await axios.get(imageUrl, options);

    if (response.status === 200) {
      const imageBlob = response.data;

      // Create a file from the blob
      const imageFile = new File(
        [imageBlob],
        `${imageName}.${imageBlob.type.split("/")[1]}`,
        { type: imageBlob.type }
      );

      return imageFile;
    } else {
      throw new Error(`Failed to fetch image: ${response.status}`);
    }
  } catch (error) {
    console.error("Error converting image URL to file:", error);
    throw error;
  }
};
