"use client";

import { FC, ReactNode, CSSProperties } from "react";
import Button from "@mui/material/Button";

import { useStyles } from "./styles";
import Spinner from "../Spinner";

interface PrimaryButtonProps {
  children: string;
  onClick?: () => void;
  variant?: "text" | "outlined" | "contained";
  type?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean;
  color?:
    | "error"
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning";
  loading?: boolean;
  icon?: ReactNode;
  iconAlignment?: "left" | "right";
  size?: "small" | "medium" | "large";
  style?: CSSProperties;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  children,
  onClick = () => {},
  variant = "contained",
  type = "button",
  className = "",
  disabled = false,
  color = "primary",
  loading = false,
  icon,
  iconAlignment = "right",
  style,
  size = "medium",
}) => {
  const { classes, cx } = useStyles({ disabled, loading, color });

  return (
    <Button
      variant={variant}
      type={type}
      className={cx(classes.button, className)}
      onClick={onClick}
      disabled={disabled}
      endIcon={icon && iconAlignment === "right" ? icon : undefined}
      startIcon={icon && iconAlignment === "left" ? icon : undefined}
      color={color}
      style={style}
      size={size}
    >
      {loading ? <Spinner size={25} color="#ffffff" /> : children}
    </Button>
  );
};

export default PrimaryButton;
