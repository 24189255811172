import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  zoomControls: {
    display: "flex",
    alignItems: "center",
    fontSize: "2rem",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    cursor: "pointer",
  },
  zoomSlider: {
    margin: theme.spacing(2, 4),
  },
  modalControls: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    gap: theme.spacing(4),
  },
  button: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
