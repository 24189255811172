import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  loadingContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  modalContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
    "& h1": {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: theme.palette.text.primary,
      textAlign: "center",
    },
    "& p": {
      color: theme.palette.text.secondary,
      textAlign: "center",
      "&:last-of-type": {
        fontSize: "0.875rem",
        opacity: 0.8,
      },
    },
  },
  buttonContainer: {
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  positionOverride: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
  rootOverride: {
    "&.MuiModal-root": {
      zIndex: 9998,
    },
  },
}));
