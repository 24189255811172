"use client";

import {
  PropsWithChildren,
  createContext,
  Dispatch,
  useReducer,
  useContext,
  useEffect,
} from "react";
import axios from "axios";
import { usePathname } from "next/navigation";

import { Generation } from "@/types/thread";
import { useAppSelector } from "@/redux/store";

const actionTypes = {
  SET_GALLERY_GENERATIONS: "SET_GALLERY_GENERATIONS",
  SET_GALLERY_LOADING: "SET_GALLERY_LOADING",
  SET_GALLERY_CURSOR: "SET_GALLERY_CURSOR",
  SET_GALLERY_FILTERS: "SET_GALLERY_FILTERS",
  SET_GALLERY_SEARCH: "SET_GALLERY_SEARCH",
  SET_GALLERY_SORT_DIRECTION: "SET_GALLERY_SORT_DIRECTION",
  SET_GALLERY_PAGINATION_STOP: "SET_GALLERY_PAGINATION_STOP",
} as const;

type GalleryFilters = {
  to: string | null;
  from: string | null;
  model: string | null;
};

type State = {
  generations: Generation[];
  loading: boolean;
  cursor: number;
  filters: GalleryFilters;
  search: string;
  sortDirection: "asc" | "desc";
  paginationStop: boolean;
};

type Action =
  | { type: typeof actionTypes.SET_GALLERY_GENERATIONS; payload: Generation[] }
  | { type: typeof actionTypes.SET_GALLERY_LOADING; payload: boolean }
  | { type: typeof actionTypes.SET_GALLERY_CURSOR; payload: number }
  | { type: typeof actionTypes.SET_GALLERY_FILTERS; payload: GalleryFilters }
  | { type: typeof actionTypes.SET_GALLERY_SEARCH; payload: string }
  | {
      type: typeof actionTypes.SET_GALLERY_SORT_DIRECTION;
      payload: "asc" | "desc";
    }
  | { type: typeof actionTypes.SET_GALLERY_PAGINATION_STOP; payload: boolean };

const initialState: State = {
  generations: [],
  loading: false,
  cursor: 1,
  filters: { to: null, from: null, model: null },
  search: "",
  sortDirection: "desc",
  paginationStop: false,
};

const GalleryContext = createContext<
  | {
      state: State;
      dispatch: Dispatch<Action>;
      getGalleryGenerations: () => void;
      updateFilter: (filter: "to" | "from" | "model", value: string) => void;
      resetFilters: () => void;
      updateSearch: (search: string) => void;
      updateSortDirection: (sortDirection: "asc" | "desc") => void;
    }
  | undefined
>(undefined);

const galleryReducer = (state: State, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_GALLERY_GENERATIONS:
      return { ...state, generations: action.payload, loading: false };
    case actionTypes.SET_GALLERY_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SET_GALLERY_CURSOR:
      return { ...state, cursor: action.payload };
    case actionTypes.SET_GALLERY_FILTERS:
      return {
        ...state,
        filters: action.payload,
        cursor: 1,
        loading: true,
        generations: [],
        paginationStop: false,
      };
    case actionTypes.SET_GALLERY_SEARCH:
      return {
        ...state,
        search: action.payload,
        cursor: 1,
        loading: true,
        generations: [],
        paginationStop: false,
      };
    case actionTypes.SET_GALLERY_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: action.payload,
        cursor: 1,
        loading: true,
        generations: [],
        paginationStop: false,
      };
    case actionTypes.SET_GALLERY_PAGINATION_STOP:
      return { ...state, paginationStop: action.payload };
    default:
      return state;
  }
};

interface GalleryProviderProps extends PropsWithChildren {
  initialState?: Partial<State>;
}

export const GalleryProvider = ({
  children,
  initialState: initialPropState = {},
}: GalleryProviderProps) => {
  const [state, dispatch] = useReducer(galleryReducer, {
    ...initialState,
    ...initialPropState,
  });
  const pathname = usePathname() as string;
  const user = useAppSelector((state) => state.user.user);

  const getGalleryGenerations = async () => {
    try {
      if (!user) return;
      dispatch({ type: actionTypes.SET_GALLERY_LOADING, payload: true });
      const cursor = state.cursor;
      const to = state.filters.to ? `&to=${state.filters.to}` : "";
      const from = state.filters.from ? `&from=${state.filters.from}` : "";
      const search = state.search ? `&search=${state.search}` : "";
      const order = state.sortDirection === "asc" ? `&order=asc` : "";
      const model = state.filters.model ? `&model=${state.filters.model}` : "";
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/user/gallery/generations/?cursor=${cursor}${to}${from}${model}${search}${order}`,
        { withCredentials: true }
      );
      const generations = response.data.generations;
      const newCursor = response.data.cursor;
      if (
        (newCursor > 1 && generations.length < 1) ||
        generations.length < 30
      ) {
        dispatch({
          type: actionTypes.SET_GALLERY_PAGINATION_STOP,
          payload: true,
        });
      }
      const updatedGenerations =
        cursor === 1 ? generations : [...state.generations, ...generations];

      dispatch({
        type: actionTypes.SET_GALLERY_GENERATIONS,
        payload: updatedGenerations,
      });
      dispatch({ type: actionTypes.SET_GALLERY_CURSOR, payload: newCursor });
    } catch (error) {
      console.error(error);
    }
    dispatch({ type: actionTypes.SET_GALLERY_LOADING, payload: false });
  };

  const updateFilter = (filter: "to" | "from" | "model", value: string) => {
    dispatch({
      type: actionTypes.SET_GALLERY_FILTERS,
      payload: { ...state.filters, [filter]: value },
    });
  };

  const resetFilters = () => {
    dispatch({
      type: actionTypes.SET_GALLERY_FILTERS,
      payload: { to: null, from: null, model: null },
    });
    dispatch({ type: actionTypes.SET_GALLERY_SEARCH, payload: "" });
  };

  const updateSearch = (search: string) => {
    dispatch({ type: actionTypes.SET_GALLERY_SEARCH, payload: search });
  };

  const updateSortDirection = (sortDirection: "asc" | "desc") => {
    dispatch({
      type: actionTypes.SET_GALLERY_SORT_DIRECTION,
      payload: sortDirection,
    });
  };

  useEffect(() => {
    if (state.cursor !== 1 || pathname !== "/gallery") return;
    getGalleryGenerations();
  }, [state.sortDirection, state.filters, state.search, pathname]);

  return (
    <GalleryContext.Provider
      value={{
        state,
        dispatch,
        getGalleryGenerations,
        updateFilter,
        resetFilters,
        updateSearch,
        updateSortDirection,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

export const useGalleryContext = () => {
  const context = useContext(GalleryContext);
  if (!context) {
    throw new Error("useGalleryContext must be used within a GalleryProvider");
  }
  return context;
};
