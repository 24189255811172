import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: "1.5rem !important",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  urlControls: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  divider: {
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  button: {
    width: "fit-content",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
