import { makeStyles } from "tss-react/mui";
import { keyframes } from "tss-react";

export const useStyles = makeStyles<{ color?: string }>()(
  (theme, { color }) => ({
    spinner: {
      position: "relative",
      width: "50px",
      "& span": {
        content: '""',
        animation: `${keyframes`
        0% {
            opacity: .1;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: .1;
        }
        `} 1.5s infinite`,
        animationFillMode: "both",
        height: "10px",
        width: "10px",
        background: color || theme.palette.secondary.main,
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        borderRadius: "50%",
        "&:nth-of-type(2)": {
          animationDelay: ".2s",
          marginLeft: "15px",
        },
        "&:nth-of-type(3)": {
          animationDelay: ".4s",
          marginLeft: "30px",
        },
      },
    },
  })
);
