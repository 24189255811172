import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    flexGrow: 1,
    minHeight: "10em",
    display: "grid",
    placeItems: "center",
    padding: theme.spacing(4),
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: "8px",
    cursor: "pointer",
    boxShadow: "none",
  },
  textContainer: {
    textAlign: "center",
  },
  heading: {
    fontWeight: 700,
    fontSize: "1.5rem !important",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  urlControls: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  divider: {
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  button: {
    width: "12em",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
