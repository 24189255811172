"use client";

import { useState, useEffect, useCallback } from "react";

export function useLocalStorage(key: string, defaultValue: any) {
  return useStorage(
    key,
    defaultValue,
    typeof window !== "undefined" ? window.localStorage : null
  );
}

export function useSessionStorage(key: string, defaultValue: any) {
  return useStorage(
    key,
    defaultValue,
    typeof window !== "undefined" ? window.sessionStorage : null
  );
}

function useStorage(
  key: string,
  defaultValue: any,
  storageObject: Storage | null
) {
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    const storageValue = storageObject ? storageObject.getItem(key) : null;
    setValue(storageValue ? JSON.parse(storageValue) : defaultValue);
  }, [key, storageObject]);

  useEffect(() => {
    if (value !== null && storageObject) {
      if (value === undefined) return storageObject.removeItem(key);
      storageObject.setItem(key, JSON.stringify(value));
    }
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(null);
  }, []);

  return [value, setValue, remove];
}
