"use client";

import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";

import { useStyles } from "./styles";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import {
  updateOnboardingCompleted,
  updateUsername,
  updateAvatar,
  setUser,
} from "@/redux/actions";
import Modal from "@/components/common/Modal";
import Button from "@/components/common/PrimaryButton";
import ImageSelector from "@/components/common/ImageSelector";
import UsernameUpdater from "./UsernameUpdater";
import ProfilePictureUpdater from "./ProfilePictureUpdater";

const ProfileDetailsConfirmation = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const onboardingComplete = Boolean(user?.onboardingComplete);
  const [open, setOpen] = useState(Boolean(user && !onboardingComplete));
  const [newUsername, setNewUsername] = useState(user?.username || "");
  const [usernameValid, setUsernameValid] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | string>("");
  const [mode, setMode] = useState<"confirm" | "edit" | "select">("confirm");
  const [imageUpdated, setImageUpdated] = useState(false);

  const handleModalClose = () => {
    setOpen(false);
    dispatch(updateOnboardingCompleted());
  };

  const handleSubmit = async () => {
    if (submitting) return;

    try {
      setSubmitting(true);
      if (user && newUsername !== user.username) {
        const usernameUpdateSuccessful = await dispatch(
          updateUsername(newUsername)
        ).unwrap();
        if (!usernameUpdateSuccessful) {
          setSubmitting(false);
          return;
        }
      }

      if (imageUpdated) {
        let updatedImage;
        if (typeof selectedImage === "string") {
          const response = await fetch(selectedImage);
          updatedImage = await response.blob();
        } else {
          updatedImage = selectedImage;
        }
        const updatedUser = await dispatch(updateAvatar(updatedImage)).unwrap();
        if (updatedUser) {
          dispatch(setUser(updatedUser));
        }
        handleModalClose();
      } else {
        handleModalClose();
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const handleDownloadExistingImage = async (existingUrl: string) => {
    try {
      const response = await fetch(existingUrl);
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setSelectedImage(imageUrl);
    } catch (error) {
      // TODO: Handle this properly...
      console.error(error);
    }
  };

  const handleChooseImage = (image: File | string) => {
    setSelectedImage(image);
    setImageUpdated(true);
    setMode("edit");
  };

  const handleSetImage = (image: File | string) => {
    setSelectedImage(image);
    setImageUpdated(true);
    setMode("confirm");
  };

  const handleCancelImageSelection = () => {
    setSelectedImage(user?.avatarUrl || "");
    setImageUpdated(false);
    setMode("confirm");
  };

  useEffect(() => {
    if (user?.avatarUrl && !user?.onboardingComplete) {
      handleDownloadExistingImage(user?.avatarUrl);
    }
  }, []);

  if (!user) return null;

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      disableBackdropClose
      showCloseIcon={false}
    >
      <div className={classes.content}>
        <h2>Confirm Account Details</h2>
        <p>You can change your details anytime in your settings</p>
        <UsernameUpdater
          newUsername={newUsername}
          setNewUsername={setNewUsername}
          existingUsername={user.username}
          usernameValid={usernameValid}
          setUsernameValid={setUsernameValid}
        />
        <div className={classes.contentGroup}>
          <h3>Profile Picture</h3>
          {mode === "edit" && (
            <ProfilePictureUpdater
              image={selectedImage}
              setImage={handleSetImage}
              onCancel={handleCancelImageSelection}
            />
          )}

          {mode === "confirm" && (
            <div className={classes.profilePictureContainer}>
              {selectedImage ? (
                <>
                  <img
                    src={
                      typeof selectedImage === "string"
                        ? selectedImage
                        : URL.createObjectURL(selectedImage)
                    }
                    alt="Profile Picture"
                    className={classes.profilePicture}
                  />

                  <EditIcon
                    className={classes.profilePictureEditIcon}
                    onClick={() => setMode("select")}
                  />
                </>
              ) : (
                <Button variant="outlined" onClick={() => setMode("select")}>
                  Add Image
                </Button>
              )}
            </div>
          )}
          {mode === "select" && (
            <ImageSelector
              setImage={handleChooseImage}
              heading="Add Image"
              uploadOnly
            />
          )}
        </div>
        <Button
          color="secondary"
          disabled={!usernameValid || mode === "edit"}
          loading={submitting}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ProfileDetailsConfirmation;
