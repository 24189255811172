import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ mode: "light" | "dark" }>()(
  (theme, { mode }) => ({
    modal: {
      [theme.breakpoints.down("md")]: {
        zIndex: 1600,
      },
    },
    container: {
      position: "absolute",
      top: "calc(60vh - 39px)",
      left: "calc(335px + 50vw - 167px)",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("lg")]: {
        top: "50%",
        left: "50%",
      },
    },
    card: {
      width: "50rem",
      maxWidth: "80vw",
      maxHeight: "85vh",
      overflowY: "auto",
      position: "relative",
      padding: theme.spacing(12, 8),
      textAlign: "center",
      color: mode === "dark" ? "#ffffff" : theme.palette.primary.main,
      background: mode === "dark" ? "#1B2431" : "#ffffff",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100svh",
        maxWidth: "100vw",
        maxHeight: "100svh",
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        "&::-webkit-scrollbar": {
          width: 0,
        },
      },
    },
    closeIcon: {
      position: "absolute",
      top: theme.spacing(4),
      right: theme.spacing(4),
      width: 24,
      height: 24,
      transform: "rotate(45deg)",
      cursor: "pointer",
      color: mode === "dark" ? "#ffffff" : theme.palette.primary.main,
      "&:hover": {
        color: mode === "dark" ? "#ececec" : theme.palette.primary.light,
      },
      [theme.breakpoints.down("md")]: {
        position: "fixed",
      },
    },
    heading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    headingIcon: {
      "& > *": {
        fontSize: "2.55rem",
      },
      [theme.breakpoints.down("sm")]: {
        "& > *": {
          fontSize: "2rem",
        },
      },
    },
    subheading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    content: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        height: "calc(100svh - 2em)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 0,
        },
      },
    },
    subheadingText: {
      textAlign: "left",
    },
  })
);
