"use client";

import { FC, ReactNode } from "react";
import dynamic from "next/dynamic";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import PromotionalBanner from "@/components/adverts/PromotionalBanner";
import ProfileDetailsConfirmation from "@/components/onboarding/ProfileDetailsConfirmation";

const DesktopLayout = dynamic(() => import("./Desktop"));
const MobileLayout = dynamic(() => import("./Mobile"));

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  if (isDesktop) {
    return (
      <>
        <PromotionalBanner />
        <DesktopLayout>
          <ProfileDetailsConfirmation />
          {children}
        </DesktopLayout>
      </>
    );
  }

  return (
    <>
      <PromotionalBanner />
      <MobileLayout>
        <ProfileDetailsConfirmation />
        {children}
      </MobileLayout>
    </>
  );
};

export default Layout;
