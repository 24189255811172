import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  banner: {
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 3000,
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 4),
  },
  bannerContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  },
  bannerContentLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  bannerText: {
    color: "#ffffff",
    fontWeight: 600,
    textAlign: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  declineButton: {
    width: "10em",
    padding: theme.spacing(1),
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#ffffff",
      opacity: 0.9,
    },
  },
  discordButton: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    width: "12em",
    padding: theme.spacing(1),
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.9,
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  closeIcon: {
    color: "#ffffff",
    cursor: "pointer",
    marginLeft: theme.spacing(2),
    "&:hover": {
      opacity: 0.9,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
