"use client";

import React, { useState, useEffect } from "react";
import Link from "next/link";
import Button from "@/components/common/PrimaryButton";
import { useAppSelector } from "@/redux/store";
import Modal from "@/components/common/Modal";
import { useStyles } from "./styles";

interface AdBlockDetectorProps {
  children: React.ReactNode;
}

const AdBlockDetector: React.FC<AdBlockDetectorProps> = ({ children }) => {
  const { classes } = useStyles();
  const [isAdBlockEnabled, setIsAdBlockEnabled] = useState(false);

  // Safely access user data with fallback
  const user = useAppSelector((state) => state.user?.user) ?? null;
  const isSubscribed = React.useMemo(() => {
    return Boolean(user && user.subscriptionType !== "free");
  }, [user]);

  useEffect(() => {
    // Skip detection for subscribed users
    if (isSubscribed) {
      return;
    }

    const detectAdBlock = async () => {
      try {
        const uBlockPatterns = [
          "pub_300x250",
          "pub_300x250m",
          "pub_728x90",
          "text-ad",
          "textAd",
          "text_ad",
          "text_ads",
          "text-ads",
          "text-ad-links",
          "ad-banner",
          "ad-container",
          "ad-placeholder",
          "ad-zone",
          "advertisement",
          "advertising",
          "adsBox",
          "adBanner",
          "adBox",
          "ads-area",
          "google_ads_iframe",
          "adsbygoogle",
          "ad-section",
          "ad-unit",
          "banner-ads",
          "sponsor-ad",
          "trc_related_container",
          "partner-ads",
          "ga-ads",
          "adform",
          "carbon-ads",
        ];

        const baits = uBlockPatterns.map((pattern) => {
          const bait = document.createElement("div");
          bait.id = `${pattern}-test`;
          bait.className = pattern;
          bait.innerHTML = "&nbsp;";
          bait.style.cssText = `
            position: absolute !important;
            top: -999px !important;
            left: -999px !important;
            width: 1px !important;
            height: 1px !important;
            display: block !important;
            visibility: visible !important;
            opacity: 1 !important;
            background: transparent !important;
            z-index: -9999 !important;
          `;
          document.body.appendChild(bait);
          return bait;
        });

        await new Promise((resolve) => setTimeout(resolve, 100));

        const checkBaits = () => {
          return baits.some((bait) => {
            const computed = window.getComputedStyle(bait);
            const isBlocked =
              computed.display === "none" ||
              computed.opacity === "0" ||
              computed.visibility === "hidden" ||
              computed.position === "fixed" ||
              bait.offsetParent === null ||
              bait.offsetHeight === 0 ||
              !document.body.contains(bait);

            return isBlocked;
          });
        };

        const checkResources = async () => {
          const resources = [
            "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
            "https://ad.doubleclick.net/ddm/adj",
            "https://cdn.ad.plus/player/adplayer.js",
          ];

          const results = await Promise.all(
            resources.map((url) =>
              fetch(url, { mode: "no-cors", cache: "no-cache" })
                .then(() => false)
                .catch(() => true)
            )
          );

          return results.some((blocked) => blocked);
        };

        const multiClassBait = document.createElement("div");
        multiClassBait.className =
          "ad-unit ad-zone adsbox doubleclick ad-placement carbon-ads";
        multiClassBait.style.cssText =
          "position: absolute; top: -9999px; left: -9999px;";
        document.body.appendChild(multiClassBait);

        const [resourcesBlocked, baitsBlocked] = await Promise.all([
          checkResources(),
          Promise.resolve(checkBaits()),
        ]);

        const multiClassBlocked =
          window.getComputedStyle(multiClassBait).display === "none" ||
          !multiClassBait.offsetParent;

        baits.forEach((bait) => bait.remove());
        multiClassBait.remove();

        const isBlocked = resourcesBlocked || baitsBlocked || multiClassBlocked;

        setIsAdBlockEnabled(isBlocked);
      } catch (error) {
        setIsAdBlockEnabled(true);
      }
    };

    // Only run detection in browser environment
    if (typeof window !== "undefined") {
      detectAdBlock();
    }
  }, [isSubscribed]);

  // If we're not in browser or still initializing, just render children
  if (typeof window === "undefined" || isSubscribed) {
    return <>{children}</>;
  }

  return (
    <>
      <Modal
        open={isAdBlockEnabled}
        onClose={() => {}}
        rootOverrides={classes.rootOverride}
        positionOverrides={classes.positionOverride}
        showCloseIcon={false}
        disableBackdropClose={true}
      >
        <div className={classes.modalContent}>
          <h1>Ad Blocker Detected</h1>
          <p>
            We noticed you are using an ad blocker. Our AI content generation is
            supported by ads, and disabling your ad blocker helps us keep the
            service running for free. Please consider turning it off or
            subscribe to continue using our site.
          </p>
          <p>After disabling your ad blocker, please refresh the page.</p>
          <div className={classes.buttonContainer}>
            <Button onClick={() => window.location.reload()}>
              Refresh Page
            </Button>
            <Link href="/subscribe" passHref>
              <Button color="secondary">Subscribe</Button>
            </Link>
          </div>
        </div>
      </Modal>
      {children}
    </>
  );
};

export default AdBlockDetector;
