"use client";

import { FC, ChangeEvent, useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";

import { useStyles } from "./styles";
import debounce from "@/utils/debounce";

interface UsernameUpdaterProps {
  newUsername: string;
  setNewUsername: (newUsername: string) => void;
  existingUsername: string;
  usernameValid: boolean;
  setUsernameValid: (valid: boolean) => void;
}

const UsernameUpdater: FC<UsernameUpdaterProps> = ({
  newUsername,
  setNewUsername,
  existingUsername,
  usernameValid,
  setUsernameValid,
}) => {
  const { classes } = useStyles();
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isValidLength = newUsername.length >= 3 && newUsername.length <= 24;

  const getStatusMessage = () => {
    if (checkingUsername)
      return { text: "Checking availability...", color: "" };
    if (!isValidLength)
      return {
        text: "Username must be between 3 and 24 characters",
        color: "red",
      };
    if (errorMessage) return { text: errorMessage, color: "red" };
    if (existingUsername === newUsername) return null;
    if (usernameValid)
      return { text: "Username is available!", color: "green" };
    return null;
  };

  const statusMessage = getStatusMessage();

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewUsername(e.target.value);
    setUsernameValid(false);
  };

  const checkUsernameAvailability = async () => {
    if (newUsername === existingUsername) {
      setUsernameValid(true);
      setErrorMessage("");
      return;
    }

    if (!isValidLength) return;
    try {
      setCheckingUsername(true);
      await axios.get(
        `${
          process.env.NEXT_PUBLIC_API_URL
        }/user/username/available?username=${newUsername.trim()}`
      );
      setErrorMessage("");
      setUsernameValid(true);
    } catch (error: any) {
      console.error(error);
      const errorMessage =
        error.response?.data?.errors[0]?.msg ||
        "Unable to check username availability";
      setErrorMessage(errorMessage);
      setUsernameValid(false);
    }
    setCheckingUsername(false);
  };

  const debouncedCheckUsername = debounce(checkUsernameAvailability, 500);

  useEffect(() => {
    if (isValidLength) {
      debouncedCheckUsername();
    }
  }, [newUsername]);

  return (
    <div className={classes.container}>
      <h3>Username</h3>
      <TextField
        value={newUsername}
        variant="standard"
        placeholder="Enter your username"
        onChange={handleUsernameChange}
        helperText={`${newUsername.length}/24`}
        className={classes.inputField}
      />
      {statusMessage !== null && (
        <p style={{ color: statusMessage.color }}>{statusMessage.text}</p>
      )}
    </div>
  );
};

export default UsernameUpdater;
