import { FC, CSSProperties } from "react";

interface SpinnerProps {
  size?: number;
  color?: string;
}

const Spinner: FC<SpinnerProps> = ({ size = 80, color = "#f50057" }) => {
  const spinnerStyle: CSSProperties = {
    display: "inline-block",
    position: "relative",
    width: `${size}px`,
    height: `${size}px`,
  };

  const divStyle = (index: number): CSSProperties => ({
    transformOrigin: `${size / 2}px ${size / 2}px`,
    animation: `spinAnimation 1.2s linear infinite`,
    transform: `rotate(${index * 30}deg)`,
    animationDelay: `-${(12 - index) * 0.1}s`,
  });

  const afterStyle: CSSProperties = {
    content: '""',
    display: "block",
    position: "absolute",
    top: `${0.0375 * size}px`,
    left: `${0.4625 * size}px`,
    width: `${0.075 * size}px`,
    height: `${0.225 * size}px`,
    borderRadius: "20%",
    backgroundColor: color,
  };

  return (
    <div style={spinnerStyle}>
      {Array.from({ length: 12 }).map((_, i) => (
        <div key={i} style={divStyle(i)}>
          <div style={afterStyle}></div>
        </div>
      ))}
      <style>{`
        @keyframes spinAnimation {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default Spinner;
