import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(8),
  },
  contentGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  profilePictureContainer: {
    position: "relative",
    width: "fit-content",
    margin: "0 auto",
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: theme.spacing(2),
  },
  profilePictureEditIcon: {
    position: "absolute",
    bottom: 15,
    right: 0,
    cursor: "pointer",
    fontSize: "2rem",
    color: "#ffffff",
    filter: `drop-shadow(0px 0px 1px ${theme.palette.primary.main})`,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));
